<template>
  <div class="managereviewers">
    <Header></Header>

    <v-container v-if="!isLoading" class="my-3">
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>Manage Reviewers</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="primary" dark>Edit Reviewers</v-toolbar>
            <v-card-title class="px-0">
              <v-container class="fluid d-lg-flex align-center">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="ml-auto mr-3"
                  dark
                  rounded
                  @click="dialog = true"
                >
                  + Add new Reviewer
                </v-btn>
              </v-container>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :loading="dataLoading"
                loading-text="Loading... Please wait"
                item-key="id"
                :disable-sort="true"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="itemsTotal"
                :sort-desc="[false, true]"
                multi-sort
                class="elevation-5 mb-15 align-center"
                :footer-props="{
                  itemsPerPage: [10]
                }"
              >
                <template v-slot:item.actions="{ item }">
                  <span class="group">
                    <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>

    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
    <v-dialog v-model="dialog" width="1000">
      <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>Add New Reviewer</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                {{ errorMessage }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                
                  <v-text-field
                    filled
                    v-model="editedItem.uid"
                    label="UID"
                    :value="editedItem.uid"
                    :rules="[v => !!v || 'UID is required']"
                  ></v-text-field>
                
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn class="back-to-blue mb-3 ml-5" rounded @click="close">
            cancel
          </v-btn>
          <v-btn
            class="mb-3 mr-3 ms-auto"
            color="secondary"
            @click="saveItem(editedItem)"
            rounded
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ReviewersService from "../services/reviewers.service";

export default {
  name: "ManageReviewers",
  components: {
    Header
  },
  data() {
    return {
      //keepDialog: false;
      dialog: false,
      breadcrumbsItems: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Manage Reviewers",
          disabled: true,
          href: "/manageReviewers"
        }
      ],
      editedItem: {
        name: "",
        description: ""
      },
      originalItem: {
        name: "",
        description: ""
      },
      defaultlItem: {
        name: "",
        description: ""
      },
      items: [],
      isLoading: false,
      snackbar: false,
      valid: true,
      alert: "",
      errorMessage: "",
      options: {},
      dataLoading: true,
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      headers: [
        { text: "Name", value: "reviewer_name" },
        { text: "UID", value: "reviewer_uid" },
        {
          text: "",
          value: "actions",
          sortable: false,
          filterable: false,
          width: "150px"
        }
      ],
      editedIndex: -1
    };
  },

  watch: {
    dialog() {
      this.errorMessage = "";
    },
    options: {
      async handler() {
        if (this.lastPage !== null) {
          this.getItems();
        }
      },
      deep: true
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.getItems();
  },

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    async getItems() {
      this.dataLoading = true;

      const { page } = this.options;

      const response = await ReviewersService.get({
        query: {
          page
        }
      });

      if (response.data.data.length > 0) {
        this.options.itemsPerPage = parseInt(response.data.meta.per_page);
        this.items = response.data.data.slice();
        this.itemsTotal = response.data.meta.total;
        this.lastPage = response.data.meta.last_page;
      }
      this.dataLoading = false;
    },

    close() {
      this.dialog = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        //check duplicates
        let duplicate = 0;
        if(this.items.length > 0) {
          duplicate = this.items.filter(item => item.reviewer_uid === item.uid);
        }
        
        if (duplicate) this.errorMessage = "The uid already exists";
        else {
          // create item
          const response = await ReviewersService.create({
            uid: item.uid
          });
          if (response.data.data) {
            this.items.push(response.data.data);
            this.showSnackbar("The Reviewer has been added successfully");
            this.errorMessage = "";
            this.dialog = false;
          } else {
            this.errorMessage = response.data.message;
            //this.keepDialog = true;
          }
        }
      }
      this.$refs.form.reset();
    },

    async deleteItem(item) {
      const index = this.items.indexOf(item);
      confirm("Are you sure you want to delete this reviewer?") &&
        this.items.splice(index, 1);

      await ReviewersService.remove({
        uid: item.reviewer.uid
      });

      this.showSnackbar(item.reviewer.name + " has been deleted");
    }
  }
};
</script>
<style lang="scss" scoped>
.full-width-buttons button.v-btn {
  width: 100% !important;
}
.disabled_check.v-icon {
  color: rgba(0, 0, 0, 0.14);
}
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.fix-detail-dashboard {
  @media screen and (min-width: 767px) {
    padding-left: 116px;
  }
  margin-top: -2px;
  padding-top: 2px;
  box-shadow: none !important;
}
</style>
